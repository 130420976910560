import React from 'react';
import { createRoot } from 'react-dom/client';
// import { Provider } from 'react-redux';
// import { store } from './app/store';
import {
  WalletProvider,
  getChainOptions,
} from '@terra-money/wallet-provider';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

getChainOptions().then(chainOptions => {
  root.render(
    <WalletProvider  {...chainOptions}>
      <App />
    </WalletProvider>
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
