import { Box } from '@mui/material';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'
import { DidNameProvider } from './contexts/didNameContext';

const BurnLunc = lazy(() => import('./pages/BurnLunc'));
const StakeLP = lazy(() => import('./pages/StakeLPV2'));

function App() {
  return (
    <DidNameProvider>
      <Router>
        <Header />
        <Suspense fallback={<Box textAlign={'center'} padding={4}>Loading...</Box>}>
          <Routes>
            <Route path="/" element={<BurnLunc />} />
            {/* <Route path="/community/*" element={<Community />} />
            <Route path="/app/register" element={<Register />} />
            <Route path="/app/nft" element={<NFTDetails />} />
            <Route path="/app" element={<LuncId />} />
            <Route path="/mynfts" element={<MyNFTs />} /> */}
            <Route path="/burnLunc" element={<BurnLunc />} />
            <Route path="/stakeLP" element={<StakeLP />} />
          </Routes>
        </Suspense>
      </Router>
    </DidNameProvider>
  );
}

export default App;
