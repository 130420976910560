import { useState, useEffect, useCallback, createContext, useContext } from 'react'
import { useLCDClient, useConnectedWallet } from '@terra-money/wallet-provider';
import { namehash, hex2ab } from '../utils';
import configs from '../configs'
const { contractAddrs } = configs

const DidNameContext = createContext()

export const DidNameProvider = ({ children }) => {
    const [didName, setDidName] = useState()
    const lcd = useLCDClient()
    const connectedWallet = useConnectedWallet();

    const updateDidName = useCallback(async () => {
        if (!connectedWallet?.terraAddress) return
        const node = new Uint8Array(hex2ab(namehash(connectedWallet?.terraAddress + '.reverse')));
        let did = await lcd.wasm.contractQuery(contractAddrs['resolver'], {get_name: { node: [...node] }}); 
        if (did?.name) {
            const completedName = did.name + '.lunc'
            // console.log('completedName', completedName)

            // getMyAllNFTs
            let result = await lcd.wasm.contractQuery(contractAddrs['registrar'], 
                {
                    tokens: {
                        owner: connectedWallet?.terraAddress,
                        start_after: '',
                        limit:10
                    }
                })
            
            const myNFTs = []

            for (let i = 0; i < result.tokens.length; i++) {
                let nftInfo = await lcd.wasm.contractQuery(contractAddrs['registrar'], { nft_info: { token_id: result.tokens[i] }}); 
                myNFTs.push(nftInfo);
            }

            const hasThisNFT = myNFTs.find(nft => nft.name === completedName) 
            // console.log('hasThisNFT', hasThisNFT)
            if (hasThisNFT) {
                setDidName(completedName)
            }
        }
    }, [connectedWallet, lcd])

    useEffect(() => {
        updateDidName()
    }, [updateDidName])

    const value = {
        didName,
        updateDidName
    }

    return (
        <DidNameContext.Provider value={value}>
            {children}
        </DidNameContext.Provider>
    )
}

export const useDidName = () => ({
    ...useContext(DidNameContext)
})