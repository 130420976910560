import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogContent, Typography, IconButton } from '@mui/material';
import './connect.scss'
import { useWallet, WalletStatus, useConnectedWallet, ConnectType } from "@terra-money/wallet-provider";
import CloseIcon from '@mui/icons-material/Close';
import BtnMenu from './BtnMenu'
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import useLocalStorageState from "use-local-storage-state";
import CheckChainType from '../CheckChainType';

export default function Connect() {
    const {
        status,
        availableConnections,
        connect,
        availableInstallations,
        connection,
        wallets
      } = useWallet();
    const connectedWallet = useConnectedWallet();
    const chainID = 'columbus-5';
    const terraAddress = connectedWallet?.addresses[chainID];

    const [connectDialog, setConnectDialog] = useLocalStorageState('connectDialog')

    const [searchParams, setSearchParams] = useSearchParams();
    const inviteCode = searchParams.get('invitationCode')

    const [open, setOpen] = React.useState(false);

    const [inviteSuccess, setInviteSuccess] = useState(false)
    const [inviting, setInviting] = useState(false)
    const [connectTrigger, setConnectTrigger] = useState(false)

    // useEffect(() => {
    //     async function api() {
    //         if (connectTrigger && connectedWallet && terraAddress) {
    //             const res = await axios.post('/api/user/connect', { address: terraAddress, referral: inviteCode })
    //             if (inviteCode && res.data?.invited) {
    //                 // invite success
    //                 setInviteSuccess(true)
    //                 setInviting(false)
    //             } else {
    //                 // normal connect
    //                 setOpen(false)
    //             }
    //             setConnectTrigger(false)
    //         }
    //     } 
    //     api().catch(() => { 
    //         setOpen(false)   
    //         setConnectTrigger(false)        
    //     })
    // }, [connectedWallet, inviteCode, inviting, connectTrigger])

    function handleConnect(type, identifier) {
        connect(type, identifier)
        setConnectTrigger(true)
        setConnectDialog(false)
        if (type == ConnectType.WALLETCONNECT || type == ConnectType.EXTENSION) {
            handleClose();
        }
    }
    // function handleInviteConnect() {
    //     setInviting(true)
    // }

    useEffect(() => {
        (inviteCode || connectDialog) && setOpen(true)
    }, [inviteCode, connectDialog])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
        setConnectDialog(false)
        if (inviteCode) {
            setTimeout(() => {
                searchParams.delete('invitationCode')
                setSearchParams(searchParams); 
                setInviteSuccess(false)
                setInviting(false)
            }, 100)
        }
    };

    // wallet will be listed here
    function Content() {
        const installStation = availableInstallations.find(v => v.identifier === 'station')
        if (installStation) {
            return (
                <Button
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        padding: 2,
                        textTransform: 'none',
                        borderTop: '1px solid #ecf0f8',
                        borderRadius: 0,
                        ":last-child": {
                            borderBottom: '1px solid #ecf0f8'
                        }
                    }}
                    href={installStation.url}
                    >
                    
                    <Typography sx={{color: '#fff', opacity: '0.8',
                        fontSize: '14px', fontWeight: 'bold'}}>
                       Install {installStation.name}
                    </Typography>
                    <img
                        src={installStation.icon}
                        alt={installStation.name}
                        style={{ width: "24px", height: "24px", marginLeft: '16px' }}
                    />
                </Button>
            )
        }
        return availableConnections.filter(({ type }) => type !== ConnectType.READONLY).map(({ type, name, icon, identifier = "" }) => (
            <Button
                key={"connection-" + type + identifier}
                onClick={() => handleConnect(type, identifier)}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: 2,
                    textTransform: 'none',
                    borderTop: '1px solid rgba(255,255,255,.15)',
                    borderRadius: 0,
                    ":last-child": {
                        borderBottom: '1px solid rgba(255,255,255,.15)'
                    }
                }}
            >
            
                <Typography sx={{fontSize: '14px', fontWeight: 'bold', color: '#fff', opacity: '0.8'}}>
                    {name}
                </Typography>
                <img
                    src={icon}
                    alt={name}
                    style={{ width: "24px", height: "24px", marginLeft: '16px' }}
                />
            </Button>
        ))
    }

    function MainContent() {
        // if (inviteCode && !inviting) {
        //     return (
        //         <Box>
        //             <Typography sx={{fontWeight: 'bold', color: 'rgb(142,158,175)', textAlign:'center'}}>
        //                 {inviteSuccess ? <Typography variant='span'>You have been <span style={{color: '#ff4e19'}}>successfully</span> invited to join</Typography> : 'You\'re invited to join'}
        //             </Typography>
        //             <Typography sx={{fontSize:"32px", lineHeight:'56px', fontWeight: 'bold', textAlign:'center'}}>LUNC ID</Typography>
        //             <Box textAlign={'center'} mt={2}>
        //                 { connectedWallet && terraAddress && <Button disabled sx={{padding: '4px 15px', textTransform: 'none', color: 'rgba(255,255,255,0.4) !important', fontWeight: 'bold', fontSize:'16px'}}>You're {inviteSuccess ? 'now' : 'already'} part of LUNC ID community</Button> }
        //                 { !connectedWallet && <Button onClick={handleInviteConnect} sx={{padding: '4px 15px', textTransform: 'none', border: '1px solid #ff4e19', color: '#ff4e19'}}>Connect</Button> }
        //             </Box>
        //         </Box>
        //     )
        // }
        return (
            <Box>
                <Typography gutterBottom className='connect-wallet' sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#fff',
                    opacity: '0.8'
                }}>
                    Connect wallet
                </Typography>
                <Box mt={4}>
                    <Content />
                </Box>
            </Box>
        )
    }

    return (
        <Box className='connect'>
            {connectedWallet && terraAddress && <CheckChainType />}
            {
                status === WalletStatus.WALLET_NOT_CONNECTED 
                    ? (<Button onClick={handleClickOpen} sx={{textTransform: 'none', color: "#ff4e19", border: '1px solid #ff4e19', borderRadius: '20px 20px', paddingLeft: '20px', paddingRight: '20px'}}>Connect</Button>) 
                    : (<BtnMenu />)
            }
            
            <Dialog 
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                PaperProps={{
                    sx: {
                        backgroundColor: '#0F1216'
                    }
                }}
            >
                <DialogContent dividers sx={{ position: 'relative', width: "440px", padding: '40px', backgroundColor: '#0F1216', color: '#fff' }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        >
                        <CloseIcon />
                    </IconButton>
                    <MainContent />
                    
                </DialogContent>
            </Dialog>
        </Box>
    )
}