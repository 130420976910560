import React from 'react';
import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import LOGO from './logo.png';
import './header.scss';
import Connect from './Connect';
import { useConnectedWallet } from '@terra-money/wallet-provider';

export default function Header() {
	let { pathname } = useLocation();
	const connectedWallet = useConnectedWallet();

	return (
		<Box className="header-wrapper">
			<Box display={'flex'} alignItems="center">
				<Link className="logo" to="/burnLunc">
					<img src={LOGO} width="60px" height="60px" alt="logo" />
				</Link>
				<Link
					className={`link ${pathname.includes('/burnLunc') && 'selected'}`}
					to="/burnLunc"
				>
					Burn Lunc🔥
				</Link>
				<Link
					className={`link ${pathname.includes('/stakeLP') && 'selected'}`}
					to="/stakeLP"
				>
					Stake LP_🚀
				</Link>
				<Link target="_blank"
					className={`link ${pathname.includes('/dex') && 'selected'}`}
					to="https://www.dfswap.io/swap?type=0&offer=uluna&ask=terra1r9laq5788d36gxmf8jkayln3g5szg4ql0nmccs"
				>
					Dex💹
				</Link>
			</Box>
			<Box display={'flex'} alignItems="center">				
				<Connect />
			</Box>
		</Box>
	);
}
