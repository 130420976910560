import pkg from 'js-sha3';
const { sha3_256: SHA256 } = pkg;


export function notifyMe(title, options = {}) {
    options.renotify = false
    options.requireInteraction = true
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("该浏览器不支持桌面通知功能");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      console.log('wtf')
      new Notification(title, options);
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          new Notification(title, options);
          // …
        }
      });
    }
  
    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
}

export function namehash(inputName) {
    let node = ''
    for (let i = 0; i < 32; i++) {
      node += '00'
    }
  
    const name = inputName;//normalize(inputName)
  
    if (name) {
      const labels = name.split('.')
  
      for(let i = labels.length - 1; i >= 0; i--) {
        const labelSha = SHA256(labels[i])
        node = SHA256(new Buffer(node + labelSha, 'hex'))
      }
    }
  
    return '0x' + node
}
  
  /**
   * 十六进制转 bytearray
   */
export function hex2ab(hex) {
    const typedArray = new Uint8Array((hex.match(/[\da-f]{2}/gi)).map(function (h) {
      return parseInt(h, 16)
    }))
  
    const buffer = typedArray.buffer
    return buffer
}

export function formatSecondsToReadableDate(endTime) {
  // 将秒数转换为毫秒数
  const milliseconds = endTime * 1000;

  // 创建 Date 对象
  const date = new Date(milliseconds);

  // 获取年、月、日、小时、分钟、秒
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // 构建可读日期字符串
  const readableDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return readableDate;
}