const configs = {
	contractAddrs_v1: {
		'dflunc': 'terra1u27kgxl68z39vvzgdkxccu6aqdwwayf0samuwg', 
		'cw20_dfc': 'terra17elux0e7pwa0l4yws6yar3xj9kaalzal90yp22', //'terra1w9p9egxz0vxavvrr9cr83thgd7avvg9ykaq5zt', // 
		'fomodfc': 'terra1jk9n5munxq6mgarq3tzkmhvrsa5lysz7pxgwrc',
		'noderewardpool': 'terra1grnta7hf9hyujp6t6yhv3vn544xccukf865ge9',  //'terra1deexfnt4gj5r4p4aft5sdjajtkek0w07m47hgy',  // 
		'burndfc': 'terra1grax3fe6y54hgguj2944u5h2qwmux7nvfths5p',
		'v1': true
	},
	contractAddrs: {
		'dflunc': 'terra1eewgymwqqp0wcdllmz36xaank8lj3fcylzj3wx',//'', 
		'cw20_dfc': 'terra1r9laq5788d36gxmf8jkayln3g5szg4ql0nmccs',
		'old_cw20_dfc': 'terra17elux0e7pwa0l4yws6yar3xj9kaalzal90yp22',
		'fomodfc': 'terra1jk9n5munxq6mgarq3tzkmhvrsa5lysz7pxgwrc',
		'burndfc': 'terra1tsrjjat9d8wquzkp9yz30scmpsjufqcldy34mf',
		'stakelp': 'terra1txgelkw2pyldrfhkzwhsrzr6eagxecfm8klgrcf44q8a5hagefdsclmuvz', //'',
		'v2': true
	},
	dividendCw20s: {
		//'all': 'All',
		'terra1r9laq5788d36gxmf8jkayln3g5szg4ql0nmccs': 'DFC',
		'terra13sagkcsdf039xfr4cthfv78f07ja8ey2eazktmktwqkve48ry3pqw79pzg': 'LUCK'
	}
};

export default configs;
